import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, CSP_NONCE } from '@angular/core';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import * as Sentry from '@sentry/angular-ivy';
import { ThemeService, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { SharedDirectivesModule } from 'src/directives/shared-directives.module';
import { environment } from 'src/environments/environment';
import { SharedPipesModule } from 'src/pipes/shared-pipes.module';
import { AnalyticsService } from 'src/services/analytics.service';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { AutomationsService } from 'src/services/automations.service';
import { CollaboratorTypesService } from 'src/services/collaborator-types.service';
import { CustomersService } from 'src/services/customers.service';
import { ExternalChatConnectionsService } from 'src/services/external-chat-connections.service';
import { GlobalCreateService } from 'src/services/global-create.service';
import { GoogleApisService } from 'src/services/google-apis.service';
import { InboundEmailrulesService } from 'src/services/inbound-email-rules.service';
import { KnowledgeBaseArticlesService } from 'src/services/knowledge-base-articles.service';
import { KnowledgeBaseScrapeJobsService } from 'src/services/knowledge-base-scrape-jobs.service';
import { MessageService } from 'src/services/message.service';
import { ProjectsService } from 'src/services/projects.service';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';
import { ServerEventsService } from 'src/services/server-events.service';
import { TagsService } from 'src/services/tags.service';
import { TenantsService } from 'src/services/tenants.service';
import { TicketTypesService } from 'src/services/ticket-types.service';
import { UploadService } from 'src/services/upload.service';
import { UserTeamsService } from 'src/services/user-teams.service';
import { UsersService } from 'src/services/users.service';
import { WebhookSubscriptionsService } from 'src/services/webhook-subscriptions.service';

import { EmojisService } from '../services/emojis.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PasswordLinkPage } from './auth/password-link/password-link.page';
import { AddMemberModule } from './components/add-member/add-member.module';
import { AvatarModule } from './components/avatar/avatar.module';
import { ComposePage } from './components/compose/compose.page';
import { CustomFieldsModule } from './components/custom-fields/custom-fields.module';
import { ResponseInputModule } from './components/custom-fields/response-input/response-input.module';
import { DatetimePickerModule } from './components/datetime-picker/datetime-picker.module';
import { EditTagModule } from './components/edit-tag/edit-tag.module';
import { EditTagLinksModule } from './components/edit-tag-links/edit-tag-links.module';
import { EditUserTagModule } from './components/edit-user-tag/edit-user-tag.module';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { MessagingModule } from './components/messaging/messaging.module';
import { SlackBlocksModule } from './components/slack-blocks/slack-blocks.module';
import { SlackTextEditorComponent } from './components/slack-text-editor/slack-text-editor.component';
import { SupportStepsModule } from './components/support-steps/support-steps.module';
import { TimePickerModule } from './components/time-picker/time-picker.module';
import { UnlayerWrapperComponent } from './components/unlayer-wrapper/unlayer-wrapper.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfilePopoverPage } from './profile/profile-popover/profile-popover.page';
import { getAppConfig, getFirebaseSettings, getCspNonce } from './utils/utils';

const subdomain = getAppConfig().subdomain;

@NgModule({
  declarations: [
    AppComponent,
    PasswordLinkPage,
    ComposePage,
    ProfilePopoverPage,
    NotificationsComponent,
    ImageModalComponent,
    FilterDropdownComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      rippleEffect: false,
    }),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(getFirebaseSettings(), subdomain)),
    provideFirestore(() => {
      const firestore = getFirestore(getApp(subdomain));

      if (environment.useEmulators) {
        connectFirestoreEmulator(firestore, 'localhost', 8082);
      }

      return firestore;
    }),
    provideAuth(() => {
      const auth = getAuth(getApp(subdomain));

      auth.tenantId = getAppConfig().tenantId ?? null;

      return auth;
    }),
    provideStorage(() => getStorage(getApp(subdomain))),
    provideAnalytics(() => getAnalytics(getApp(subdomain))),
    FormsModule,
    HttpClientModule,
    NgxTippyModule,
    ImageCropperModule,
    CustomFieldsModule,
    SlackBlocksModule,
    DatetimePickerModule,
    ResponseInputModule,
    UnlayerWrapperComponent,
    SlackTextEditorComponent,
    AvatarModule,
    SharedPipesModule,
    MessagingModule,
    NgSelectModule,
    SharedDirectivesModule,
    EditUserTagModule,
    EditTagLinksModule,
    EditTagModule,
    SupportStepsModule,
    AddMemberModule,
    TimePickerModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MonacoEditorModule.forRoot(),
  ],
  providers: [
    UploadService,
    AuthService,
    ApiService,
    ScreenTrackingService,
    UserTrackingService,
    ThemeService,
    AnalyticsService,
    MessageService,
    UsersService,
    TenantsService,
    CustomersService,
    TagsService,
    TicketTypesService,
    ProjectsService,
    CollaboratorTypesService,
    InboundEmailrulesService,
    UserTeamsService,
    ServerEventsService,
    WebhookSubscriptionsService,
    KnowledgeBaseArticlesService,
    KnowledgeBaseScrapeJobsService,
    SearchablePopoverService,
    GlobalCreateService,
    ExternalChatConnectionsService,
    GoogleApisService,
    EmojisService,
    AutomationsService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: APP_INITIALIZER,
      deps: [AuthService],
      useFactory: (authService) => () => new Promise((resolve) => authService.onAuthReady(() => resolve(true))),
      multi: true,
    },
    {
      provide: CSP_NONCE,
      useFactory: () => {
        return getCspNonce();
      },
    },
    provideCharts(withDefaultRegisterables()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
